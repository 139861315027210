//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  Row,
  Col,
  Image,
  Button,
  Message,
  TabPane,
  Tabs,
  Icon,
  Drawer,
  Switch,
  Link,
  Avatar
} from 'element-ui'
import { ctrlCourse, ctrlComment } from '@/controller'
import Player from '@vimeo/player'
import { Comment } from '@/components'
import styles from '@/assets/scss/var.scss'

const tabletSize = styles.tabletSize
export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Button.name]: Button,
    [TabPane.name]: TabPane,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
    [Drawer.name]: Drawer,
    [Switch.name]: Switch,
    [Link.name]: Link,
    [Avatar.name]: Avatar,
    Comment
  },
  data() {
    return {
      courseInfo: {},
      chpdInfo: {}, //當前影片信息
      isShowSpec: false,
      vimeoPlayer: null, //播放器實例對象
      old_seconds: 0, //-5
      isShowChapter: true,
      tabActive: 0,
      // commentDrawer: false,
      switchValue: true,
      commentList: [],
      replyList: [],
      commentNum: 0,
      replyNum: 0,
      commentSet: {
        page: 1,
        loading: false,
        pageTotal: 1 //總頁數
      },
      replySet: {
        page: 1,
        loading: false,
        pageTotal: 1 //總頁數
      },
      currReplyItem: {},
      ifOver: false, // 文本是否超出三行，默認否
      unfold: false, // 文本是否是展開狀態 默認為收起
      courseContentHeight: ''
    }
  },
  created() {
    this.getInit()
  },

  updated() {
    // this.scrollToView();
  },
  mounted() {
    this.courseContentHeight = `${document.documentElement.clientHeight}`//默認值
    const that = this
    window.onresize = () => {
      return (() => {
        window.courseContentHeight = document.documentElement.clientHeight
        that.courseContentHeight = window.courseContentHeight
      })()
    }
  },
  props: {
    ptNbr: String
  },
  computed: {
    chpdNbr: {
      //默認由其他頁面傳遞影片Nbr參數，如果不存在則取getCourseInfo.ChapterList[0].ChpdList[0].chpdNbr; 所以該值一定會存在
      get() {
        return this.$route.query.chpdNbr
      }
    }
  },
  watch: {
    chpdNbr: {
      handler(newVal, oldVal) {
        console.log(oldVal)
        if (oldVal) {
          if (this.vimeoPlayer) {
            //關閉監聽
            this.vimeoPlayer.off('play')
            this.vimeoPlayer.off('pause')
            this.vimeoPlayer.off('timeupdate')
            this.vimeoPlayer.off('ended')
            this.old_seconds = 0
          }

          this.getInit()
        }
      }
    },
    courseContentHeight: function(val) {
      this.courseContentHeight = val
      this.dynamicContainerHeight()
    },
    courseInfo: function() {
      this.dynamicContainerHeight()
    }
  },
  methods: {
    async getInit() {
      await this.getCourseInfo();
      await this.initCommentList();
      // 順序 getCourseInfo => getChpdInfo => getVideo => getPlaybackByChpd
      if (this.$refs.spanBox) {
        this.ifOver =
          this.$refs.spanBox.offsetHeight > this.$refs.textBox.offsetHeight
      }
    },
    //獲取課程詳情
    async getCourseInfo() {
      this.courseInfo = await ctrlCourse.getCourseInfo({
        ptNbr: this.ptNbr,
        chpdNbr: this.chpdNbr
      })
      console.log('課程詳情courseInfo')
      console.log(this.courseInfo)
      if (this.courseInfo?.error) return Message.error(this.courseInfo.error)
      this.getChpdInfo()
    },
    //獲取當前課程影片信息
    async getChpdInfo() {
      this.chpdInfo = await ctrlCourse.getChpdInfo({
        chpdNbr: this.chpdNbr || this.courseInfo?.chpdCurr.chpdNbr
      })
      console.log('當前課程影片信息chpdInfo')
      console.log(this.chpdInfo)
      if (this.chpdInfo) this.getVideo()
      // setTimeout(() => {

      // }, 10000);
    },
    //點擊章節課程
    clickChpd(chpd) {
      if (this.chpdNbr == chpd.chpdNbr) return

      this.$router.push({
        path: this.$route.path,
        query: { chpdNbr: chpd.chpdNbr }
      })
    },
    //影片下載
    async downLoad(sn, funName) {
      const data = await ctrlCourse.downLoad({
        chpdNbr: this.chpdNbr,
        sn,
        funName
      })
      window.open(data, '_blank')
    },
    //掛載影片至節點
    async getVideo() {
      const { height, width, player, title } = this.chpdInfo
      const id = +player.uri.split('/').splice(-1, 1)[0]
      const options = {
        id,
        height,
        width,
        title,
        responsive: true,
        autoplay: true
      }
      // const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
      // this.src = player.html.match(srcReg)[1];
      if (!this.vimeoPlayer) {
        this.vimeoPlayer = await new Player(
          document.getElementById('course_vimeo'),
          options
        )
        console.log('掛載影片至節點getVideo')
        // this.vimeoPlayer = new Vimeo.Player(document.getElementById("course_iframe"));
      } else {
        await this.vimeoPlayer.loadVideo(id)
      }
      this.$nextTick(() => {
        //右側導航最大高度計算
        let videoHeight = document.querySelector('#course_vimeo')
          ? document.querySelector('#course_vimeo').clientHeight
          : 400
        let videoTitle = document.querySelector('.course_title')
          ? document.querySelector('.course_title').clientHeight
          : 0
        if (document.querySelector('.course_content-right')) {
          document.querySelector('.course_content-right').style.maxHeight =
            videoHeight + videoTitle + 'px'
        }
      })
      //影片準備就緒
      this.vimeoPlayer.ready().then(async () => {
        const _position = await ctrlCourse.getPlaybackByChpd(this.chpdNbr)
        let position = ''
        _position == -1 ? (position = 0) : (position = _position)
        if (position || position == 0) {
          console.log('影片準備就緒getPlaybackByChpd')
          console.log(position)
          await this.vimeoPlayer.setCurrentTime(position)
          console.log('跳躍影片節點setCurrentTime')
          //影片播放事件回調
          this.vimeoPlayer.on('play', async (data) => {
            console.log(data)
            console.log('Player in the play...')
            if (data)
              ctrlCourse.heartbeat({
                chpdNbr: this.chpdNbr,
                position: data.seconds,
                type: 1
              })
          })
          //影片時間變化事件回調
          this.vimeoPlayer.on('timeupdate', async (data) => {
            if (new Date().getTime() - this.old_seconds >= 5000) {
              console.log(data)
              console.log('Player in the timeupdate...')
              this.old_seconds = new Date().getTime()
              ctrlCourse.heartbeat({
                chpdNbr: this.chpdNbr,
                position: data.seconds,
                type: 0
              })
            }
          })
          //影片進度變化事件回調
          // this.vimeoPlayer.on("seeking", async (data) => {
          // 	console.log("Player in the seeking...");
          // 	console.log(data);
          // 	if (data) ctrlCourse.heartbeat({ chpdNbr: this.chpdNbr, position: data.seconds, type: 0 });
          // });
          //影片暫停事件回調
          this.vimeoPlayer.on('pause', async (data) => {
            console.log('Player in the paused...')
            console.log(data)
            if (data)
              ctrlCourse.heartbeat({
                chpdNbr: this.chpdNbr,
                position: data.seconds,
                type: 2
              })
          })
          //影片播放結束回調
          this.vimeoPlayer.on('ended', async (data) => {
            console.log('Player in the ended...')
            if (data)
              ctrlCourse.heartbeat({
                chpdNbr: this.chpdNbr,
                position: -1,
                type: 4
              })
            if (this.courseInfo?.videoList) {
              const videoList = this.courseInfo.videoList
              for (let index = 0; index < videoList.length; index++) {
                if (videoList[index].chpdNbr == this.chpdNbr) {
                  if (videoList[index + 1]) {
                    const currentRoute = this.$router.currentRoute
                    this.$router.push({
                      path: currentRoute.path,
                      query: { chpdNbr: videoList[index + 1].chpdNbr }
                    })
                    break
                  }
                }
              }
            }
          })
        }
      })
    },
    async getCommentList() {
      const data = await ctrlComment.getCommentList(
        this.switchValue,
        this.ptNbr,
        this.chpdNbr,
        this.commentSet.page
      )
      if (data) {
        this.commentList = data.list
        this.commentNum = data.total
        // this.commentSet.pageTotal = data.end;
        // this.commentSet.loading = false;
        // this.$refs.comment.commentDrawer = true;
        console.log('-----------')
        console.log(this.commentList)
      }
    },
    //頁碼變化
    async onPageChange(page) {
      this.commentSet.page = page
      await this.getCommentList()
      this.$nextTick(() => {
        this.$el.querySelector('#comment-content').scrollIntoView({
          behavior: 'smooth', // 平滑過渡
          block: 'start' // 上邊框與視窗頂部平齊。默認值
        })
      })
    },
    replyLoad() {
      this.replySet.loading = true
      setTimeout(() => {
        this.replySet.page += 1 //頁數+1
        this.getReplyList() //調用接口，此時頁數+1，查詢下一頁數據
      }, 300)
    },
    async commentSend(commentText) {
      const data = await ctrlComment.addComment(commentText, this.chpdNbr)
      if (data) {
        Message.success('評論成功')
        // console.log("data---");
        // console.log(data);
        data.CmtInfo.isReplyTextShow = false
        this.$refs.comment.commentText = null
        this.commentList.unshift(data.CmtInfo)
        this.commentNum += 1
        ctrlComment.clearCommentInfo()
        this.initCommentList()
      }
    },
    async replySend(item, cmtNbr, commentText) {
      const data = await ctrlComment.addReply(commentText, cmtNbr)
      if (data) {
        Message.success('回覆成功')
        this.$refs.comment.reply = null
        if (this.Common.isExist(item.childrenComments)) {
          item.childrenComments.unshift(data.CmtInfo)
        } else {
          item.childrenComments = []
          item.childrenComments.unshift(data.CmtInfo)
        }

        if (this.currReplyItem.cmtNbr) {
          this.replyList.unshift(data.CmtInfo)
        }
        item.childrenTotal += 1
        // ctrlComment.clearCommentInfo();
        // this.initCommentList();
        // if(this.currReplyItem.cmtNbr) this.initReplyList();
      }
    },
    //點擊更多，查看留言詳情列表
    async moreClick(item) {
      this.replySet.loading = true
      this.replySet.page = 1
      this.replyList = []
      this.currReplyItem = item
      this.getReplyList()
    },
    async getReplyList() {
      const data = await ctrlComment.getReplyList(
        this.currReplyItem.cmtNbr,
        this.replySet.page
      )
      if (data) {
        console.log('回覆詳情')
        console.log(data)
        this.replyList = this.replyList.concat(data.list)
        this.replyNum = data.total
        this.replySet.pageTotal = data.end
        this.replySet.loading = false
        console.log('-----------')
        console.log(this.replyList)
      }
    },
    initCommentList() {
      this.commentSet.page = 1
      // this.commentList = [];
      this.getCommentList()
    },
    initReplyList() {
      this.replySet.loading = true
      this.replySet.page = 1
      this.replyList = []
      this.getReplyList()
    },
    switchValueChange() {
      // ctrlComment.clearCommentInfo();
      this.initCommentList()
    },
    scrollToView() {
      // 滾動到當前章節的指定位置
      const cpdNbr = this.$route.query.chpdNbr
      if (this.courseInfo && this.courseInfo.ChapterList.length) {
        setTimeout(() => {
          if (this.Common.isExist(this.$refs['ref_' + cpdNbr])) {
            this.$refs['ref_' + cpdNbr][0].scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'nearest'
            })
          }
        }, 500)
      }
    },
    // 獲取元素在頁面距離頂部的偏移量
    getElementTop(el) {
      // 獲取element元素距離父元素的offsetTop值
      let actualTop = el.offsetTop
      let current = el.offsetParent
      // 判斷當前元素是否循環到html根元素
      while (current !== null) {
        // offsetTop值循環相加
        actualTop += current.offsetTop
        // 當current為html根元素時，current.offsetParent值為null
        current = current.offsetParent
      }
      // 得到offsetTop循環相加值之和：即元素距離頂部的高
      return actualTop
    },
    dynamicContainerHeight() {
      this.$nextTick(()=>{
        if (this.$refs.pageContainer && this.$refs.courseContainer) {
          // pc版
          if (window.matchMedia(`(min-width: ${tabletSize})`).matches) {
            this.$refs.courseContainer.style.overflow = 'hidden'
            const el = document.getElementById('course_container')
            if (el) {
              const top = this.getElementTop(el)
              // 動態修改樣式
              this.$refs.courseContainer.style.height = (this.courseContentHeight - top - 2) + 'px'
            }
          } else {
            // 手機版
            this.$refs.courseContainer.style.overflow = 'auto'
            this.$refs.courseContainer.style.height = 'auto'
          }
        }
      })
      
    }
  }
}
